import PropTypes from "prop-types";
import {useQuery} from "react-query";
import {currencies} from "./currency-data";

const exchangeApiUrl = `https://api.ccgtrader.co.uk/_/custom/exchange_rates`;
const oneHour = 1000 * 60 * 60;

const useExchangeRates = () => {
    return useQuery([`exchange-rates`], getExchangeRates, { staleTime: oneHour });
}

const getExchangeRates = async () => {
    const response = await fetch(exchangeApiUrl);
    if (!response.ok) {
        const message = `An error has occured: ${response.status}`;
        throw new Error(message);
    }
    return response.json();
}

const convertCurrency = ({ exchangeRates, fromCurrency, toCurrency, value }) => {
    const toCurrencyData = currencies.find(currency => currency.AlphabeticCode === toCurrency);
    const minorUnit = toCurrencyData.MinorUnit || 2; // To convert value which is in amount_cents, e.g. 100 cents = 1 dollar
    const availableCurrencies = Object.prototype.hasOwnProperty.call(exchangeRates,`USD`) ? Object.keys(exchangeRates) : [];
    if (toCurrency === fromCurrency) {
        return value / 10 ** minorUnit;
    }
    if (!availableCurrencies.includes(fromCurrency) || !availableCurrencies.includes(toCurrency)) {
        return null;
    }
    return value / 10 ** minorUnit * exchangeRates[toCurrency] / exchangeRates[fromCurrency];
}

convertCurrency.propTypes = {
    exchangeRates: PropTypes.object.isRequired,
    fromCurrency: PropTypes.string.isRequired,
    toCurrency: PropTypes.string.isRequired,
    value: PropTypes.number.isRequired,
};

export { convertCurrency, useExchangeRates }
