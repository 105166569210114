import {directusClient} from "../../hooks/useDirectus";

const getMyOrders = async ({ status }) => {
    const orderParams = {
        status: status,
    };
    const { data } = await directusClient.get(`/custom/orders`, orderParams);
    return data;
}
const getMyShopOrders = async ({ status, sort }) => {
    const orderParams = { status, shop:true, sort };
    const { data } = await directusClient.get(`/custom/orders`, orderParams);
    return data;
}
const getOrder = async (id) => {
    const { data } = await directusClient.get(`/custom/orders/${id}`);
    return data;
}
const createOrder = async ({ marketId }) => {
    // Directus API checks for existing draft/pending order for this market/seller before creating a new one.
    const orderBody = {
        market_id: marketId,
    };
    const {data: order} = await directusClient.post(`/custom/orders`, orderBody);
    return {
        id: order.id,
        marketId: order.market_id,
        reference: order.reference,
    };
}
const updateOrder = async ({ id, updates }) => {
    return directusClient.patch(`/custom/orders/${id}`, updates);
}
const deleteOrder = ({ id }) => {
    if (id < 1 ) { return false; }
    return directusClient.delete(`/custom/orders/${id}`);
}

const getOrderAvailablePaymentMethods = async (orderId) => {
    const {data} = await directusClient.get(`/custom/orders/${orderId}/available_payment_methods`);
    return data;
}
const getOrderBillingAddress = async (orderId) => {
    const {data: address} = await directusClient.get(`/custom/orders/${orderId}/billing_address`);
    return address.data;
}
const getOrderCaptures = async (orderId) => {
    const {data: captures} = await directusClient.get(`/custom/orders/${orderId}/captures`);
    return captures.data;
}
const getOrderCustomer = async (orderId) => {
    const {data: customer} = await directusClient.get(`/custom/orders/${orderId}/customer`);
    return customer.data;
}
const getOrderMarket = async (orderId) => {
    const {data: market} = await directusClient.get(`/custom/orders/${orderId}/market`);
    return market.data;
}
const getOrderPaymentSource = async (orderId) => {
    const { data } = await directusClient.get(`/custom/orders/${orderId}/payment_source`);
    return data;
}
const getOrderRefunds = async (orderId) => {
    const {data: refunds} = await directusClient.get(`/custom/orders/${orderId}/refunds`);
    return refunds.data;
}
const getOrderShipments = async (orderId) => {
    const {data: shipments} = await directusClient.get(`/custom/orders/${orderId}/shipments`);
    return shipments;
}
const getOrderShippingAddress = async (orderId) => {
    const {data: address} = await directusClient.get(`/custom/orders/${orderId}/shipping_address`);
    return address.data;
}

const createLineItem = async ({ imageUrl, name, orderId, quantity, skuCode }) => {
    const lineItemBody = {
        image_url: imageUrl,
        name: name,
        order_id: orderId,
        quantity: quantity,
        sku_code: skuCode,
    };
    const {data: lineItem} = await directusClient.post(`/custom/line_items`, lineItemBody);
    return {
        id: lineItem.id,
        total_amount_cents: lineItem.total_amount_cents,
        image_url: lineItem.image_url,
        name: lineItem.name,
        quantity: lineItem.quantity,
        skuCode: lineItem.sku_code
    };
}
const updateLineItem = async ({ id, quantity }) => {
    const lineItemBody = {
        quantity: quantity,
    };
    const {data: lineItem} =  await directusClient.patch(`/custom/line_items/${id}`, lineItemBody);

    return {
        id: lineItem.id,
        total_amount_cents: lineItem.total_amount_cents,
        image_url: lineItem.image_url,
        name: lineItem.name,
        quantity: lineItem.quantity,
        skuCode: lineItem.sku_code
    };
}
const deleteLineItem = ({ id }) => {
    if (id < 1 ) { return false; }
    return directusClient.delete(`/custom/line_items/${id}`);
}

const updateCapture = async ({ id, updates }) => {
    return directusClient.patch(`/custom/captures/${id}`, updates);
}

const getPaypalPaymentByPaypalId = async (paypalId) => {
    const { data } = await directusClient.get(`/custom/paypal_payments?paypal_id=${paypalId}`);
    return data;
}
const createPaypalPayment = async ({ orderId }) => {
    const paymentBody = {
        order_id: orderId,
    };
    const {data} = await directusClient.post(`/custom/paypal_payments`, paymentBody);
    return data;
}
const updatePaypalPayment = async ({ id, updates }) => {
    return directusClient.patch(`/custom/paypal_payments/${id}`, updates);
}

const updateShipment = async ({ id, updates }) => {
    return directusClient.patch(`/custom/shipments/${id}`, updates);
}

export {
    getMyOrders,
    getMyShopOrders,
    getOrder,
    createOrder,
    updateOrder,
    deleteOrder,
    getOrderAvailablePaymentMethods,
    getOrderBillingAddress,
    getOrderCaptures,
    getOrderCustomer,
    getOrderMarket,
    getOrderPaymentSource,
    getOrderRefunds,
    getOrderShipments,
    getOrderShippingAddress,
    createLineItem,
    updateLineItem,
    deleteLineItem,
    updateCapture,
    getPaypalPaymentByPaypalId,
    createPaypalPayment,
    updatePaypalPayment,
    updateShipment
}
