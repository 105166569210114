import * as React from "react";
import PropTypes from "prop-types";
import { Skeleton } from "@material-ui/lab";
import { useUIState } from "../app/uiContext";
import { useExchangeRates } from "../utils/currency-exchange";
import { convertCurrency } from "../utils/currency-exchange";

export const LocalPrice = ({ className, fromCurrencyCode, fromPrice }) => {
    const [ convertedPrice, setConvertedPrice ] = React.useState(fromPrice);
    const { displayCurrency, userLocale } = useUIState();
    const { isLoading: isLoadingRates, data: exRates } = useExchangeRates();

    React.useEffect(() => {
        if (exRates) {
            const convertedValue = convertCurrency({
                exchangeRates: exRates ? exRates.conversion_rates : {},
                fromCurrency: fromCurrencyCode,
                toCurrency: displayCurrency,
                value: fromPrice,
            });
            setConvertedPrice(convertedValue);
        }
    }, [displayCurrency, exRates, fromCurrencyCode, fromPrice]);

    if (isLoadingRates) { return <Skeleton animation="pulse" variant="text" /> }

    return <span className={className}>{(new Intl.NumberFormat(userLocale, { style: `currency`, currency: displayCurrency }).format(convertedPrice))}</span>
};

LocalPrice.propTypes = {
    className: PropTypes.string,
    fromCurrencyCode: PropTypes.string.isRequired,
    fromPrice: PropTypes.number.isRequired
};
