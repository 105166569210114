import {useQuery} from "react-query";
import PropTypes from "prop-types";
import {useAuth} from "../../context/authContext";
import * as userClient from "./client";

const useUser = (userId) => {
    const {isLoggedIn} = useAuth();
    const enabled = userId > 0 || isLoggedIn();

    return useQuery([`user`, userId], () => userClient.getUser(userId), {enabled: enabled, staleTime: 60000});
}

useUser.propTypes = {
    userId: PropTypes.number
};

export {useUser}
