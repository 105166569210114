export const currencies = [
  {
    "Entity": `AFGHANISTAN`,
    "Currency": `Afghani`,
    "AlphabeticCode": `AFN`,
    "NumericCode": 971,
    "MinorUnit": 2
  },
  {
    "Entity": `ÅLAND ISLANDS`,
    "Currency": `Euro`,
    "AlphabeticCode": `EUR`,
    "NumericCode": 978,
    "MinorUnit": 2
  },
  {
    "Entity": `ALBANIA`,
    "Currency": `Lek`,
    "AlphabeticCode": `ALL`,
    "NumericCode": 8,
    "MinorUnit": 2
  },
  {
    "Entity": `ALGERIA`,
    "Currency": `Algerian Dinar`,
    "AlphabeticCode": `DZD`,
    "NumericCode": 12,
    "MinorUnit": 2
  },
  {
    "Entity": `AMERICAN SAMOA`,
    "Currency": `US Dollar`,
    "AlphabeticCode": `USD`,
    "NumericCode": 840,
    "MinorUnit": 2
  },
  {
    "Entity": `ANDORRA`,
    "Currency": `Euro`,
    "AlphabeticCode": `EUR`,
    "NumericCode": 978,
    "MinorUnit": 2
  },
  {
    "Entity": `ANGOLA`,
    "Currency": `Kwanza`,
    "AlphabeticCode": `AOA`,
    "NumericCode": 973,
    "MinorUnit": 2
  },
  {
    "Entity": `ANGUILLA`,
    "Currency": `East Caribbean Dollar`,
    "AlphabeticCode": `XCD`,
    "NumericCode": 951,
    "MinorUnit": 2
  },
  {
    "Entity": `ANTARCTICA`,
    "Currency": `No universal currency`,
    "AlphabeticCode": ``,
    "NumericCode": ``,
    "MinorUnit": ``
  },
  {
    "Entity": `ANTIGUA AND BARBUDA`,
    "Currency": `East Caribbean Dollar`,
    "AlphabeticCode": `XCD`,
    "NumericCode": 951,
    "MinorUnit": 2
  },
  {
    "Entity": `ARGENTINA`,
    "Currency": `Argentine Peso`,
    "AlphabeticCode": `ARS`,
    "NumericCode": 32,
    "MinorUnit": 2
  },
  {
    "Entity": `ARMENIA`,
    "Currency": `Armenian Dram`,
    "AlphabeticCode": `AMD`,
    "NumericCode": 51,
    "MinorUnit": 2
  },
  {
    "Entity": `ARUBA`,
    "Currency": `Aruban Florin`,
    "AlphabeticCode": `AWG`,
    "NumericCode": 533,
    "MinorUnit": 2
  },
  {
    "Entity": `AUSTRALIA`,
    "Currency": `Australian Dollar`,
    "AlphabeticCode": `AUD`,
    "NumericCode": 36,
    "MinorUnit": 2
  },
  {
    "Entity": `AUSTRIA`,
    "Currency": `Euro`,
    "AlphabeticCode": `EUR`,
    "NumericCode": 978,
    "MinorUnit": 2
  },
  {
    "Entity": `AZERBAIJAN`,
    "Currency": `Azerbaijan Manat`,
    "AlphabeticCode": `AZN`,
    "NumericCode": 944,
    "MinorUnit": 2
  },
  {
    "Entity": `BAHAMAS (THE)`,
    "Currency": `Bahamian Dollar`,
    "AlphabeticCode": `BSD`,
    "NumericCode": 44,
    "MinorUnit": 2
  },
  {
    "Entity": `BAHRAIN`,
    "Currency": `Bahraini Dinar`,
    "AlphabeticCode": `BHD`,
    "NumericCode": 48,
    "MinorUnit": 3
  },
  {
    "Entity": `BANGLADESH`,
    "Currency": `Taka`,
    "AlphabeticCode": `BDT`,
    "NumericCode": 50,
    "MinorUnit": 2
  },
  {
    "Entity": `BARBADOS`,
    "Currency": `Barbados Dollar`,
    "AlphabeticCode": `BBD`,
    "NumericCode": 52,
    "MinorUnit": 2
  },
  {
    "Entity": `BELARUS`,
    "Currency": `Belarusian Ruble`,
    "AlphabeticCode": `BYN`,
    "NumericCode": 933,
    "MinorUnit": 2
  },
  {
    "Entity": `BELGIUM`,
    "Currency": `Euro`,
    "AlphabeticCode": `EUR`,
    "NumericCode": 978,
    "MinorUnit": 2
  },
  {
    "Entity": `BELIZE`,
    "Currency": `Belize Dollar`,
    "AlphabeticCode": `BZD`,
    "NumericCode": 84,
    "MinorUnit": 2
  },
  {
    "Entity": `BENIN`,
    "Currency": `CFA Franc BCEAO`,
    "AlphabeticCode": `XOF`,
    "NumericCode": 952,
    "MinorUnit": 0
  },
  {
    "Entity": `BERMUDA`,
    "Currency": `Bermudian Dollar`,
    "AlphabeticCode": `BMD`,
    "NumericCode": 60,
    "MinorUnit": 2
  },
  {
    "Entity": `BHUTAN`,
    "Currency": `Indian Rupee`,
    "AlphabeticCode": `INR`,
    "NumericCode": 356,
    "MinorUnit": 2
  },
  {
    "Entity": `BHUTAN`,
    "Currency": `Ngultrum`,
    "AlphabeticCode": `BTN`,
    "NumericCode": 64,
    "MinorUnit": 2
  },
  {
    "Entity": `BOLIVIA (PLURINATIONAL STATE OF)`,
    "Currency": `Boliviano`,
    "AlphabeticCode": `BOB`,
    "NumericCode": 68,
    "MinorUnit": 2
  },
  {
    "Entity": `BONAIRE, SINT EUSTATIUS AND SABA`,
    "Currency": `US Dollar`,
    "AlphabeticCode": `USD`,
    "NumericCode": 840,
    "MinorUnit": 2
  },
  {
    "Entity": `BOSNIA AND HERZEGOVINA`,
    "Currency": `Convertible Mark`,
    "AlphabeticCode": `BAM`,
    "NumericCode": 977,
    "MinorUnit": 2
  },
  {
    "Entity": `BOTSWANA`,
    "Currency": `Pula`,
    "AlphabeticCode": `BWP`,
    "NumericCode": 72,
    "MinorUnit": 2
  },
  {
    "Entity": `BOUVET ISLAND`,
    "Currency": `Norwegian Krone`,
    "AlphabeticCode": `NOK`,
    "NumericCode": 578,
    "MinorUnit": 2
  },
  {
    "Entity": `BRAZIL`,
    "Currency": `Brazilian Real`,
    "AlphabeticCode": `BRL`,
    "NumericCode": 986,
    "MinorUnit": 2
  },
  {
    "Entity": `BRITISH INDIAN OCEAN TERRITORY (THE)`,
    "Currency": `US Dollar`,
    "AlphabeticCode": `USD`,
    "NumericCode": 840,
    "MinorUnit": 2
  },
  {
    "Entity": `BRUNEI DARUSSALAM`,
    "Currency": `Brunei Dollar`,
    "AlphabeticCode": `BND`,
    "NumericCode": 96,
    "MinorUnit": 2
  },
  {
    "Entity": `BULGARIA`,
    "Currency": `Bulgarian Lev`,
    "AlphabeticCode": `BGN`,
    "NumericCode": 975,
    "MinorUnit": 2
  },
  {
    "Entity": `BURKINA FASO`,
    "Currency": `CFA Franc BCEAO`,
    "AlphabeticCode": `XOF`,
    "NumericCode": 952,
    "MinorUnit": 0
  },
  {
    "Entity": `BURUNDI`,
    "Currency": `Burundi Franc`,
    "AlphabeticCode": `BIF`,
    "NumericCode": 108,
    "MinorUnit": 0
  },
  {
    "Entity": `CABO VERDE`,
    "Currency": `Cabo Verde Escudo`,
    "AlphabeticCode": `CVE`,
    "NumericCode": 132,
    "MinorUnit": 2
  },
  {
    "Entity": `CAMBODIA`,
    "Currency": `Riel`,
    "AlphabeticCode": `KHR`,
    "NumericCode": 116,
    "MinorUnit": 2
  },
  {
    "Entity": `CAMEROON`,
    "Currency": `CFA Franc BEAC`,
    "AlphabeticCode": `XAF`,
    "NumericCode": 950,
    "MinorUnit": 0
  },
  {
    "Entity": `CANADA`,
    "Currency": `Canadian Dollar`,
    "AlphabeticCode": `CAD`,
    "NumericCode": 124,
    "MinorUnit": 2
  },
  {
    "Entity": `CAYMAN ISLANDS (THE)`,
    "Currency": `Cayman Islands Dollar`,
    "AlphabeticCode": `KYD`,
    "NumericCode": 136,
    "MinorUnit": 2
  },
  {
    "Entity": `CENTRAL AFRICAN REPUBLIC (THE)`,
    "Currency": `CFA Franc BEAC`,
    "AlphabeticCode": `XAF`,
    "NumericCode": 950,
    "MinorUnit": 0
  },
  {
    "Entity": `CHAD`,
    "Currency": `CFA Franc BEAC`,
    "AlphabeticCode": `XAF`,
    "NumericCode": 950,
    "MinorUnit": 0
  },
  {
    "Entity": `CHILE`,
    "Currency": `Chilean Peso`,
    "AlphabeticCode": `CLP`,
    "NumericCode": 152,
    "MinorUnit": 0
  },
  {
    "Entity": `CHINA`,
    "Currency": `Yuan Renminbi`,
    "AlphabeticCode": `CNY`,
    "NumericCode": 156,
    "MinorUnit": 2
  },
  {
    "Entity": `CHRISTMAS ISLAND`,
    "Currency": `Australian Dollar`,
    "AlphabeticCode": `AUD`,
    "NumericCode": 36,
    "MinorUnit": 2
  },
  {
    "Entity": `COCOS (KEELING) ISLANDS (THE)`,
    "Currency": `Australian Dollar`,
    "AlphabeticCode": `AUD`,
    "NumericCode": 36,
    "MinorUnit": 2
  },
  {
    "Entity": `COLOMBIA`,
    "Currency": `Colombian Peso`,
    "AlphabeticCode": `COP`,
    "NumericCode": 170,
    "MinorUnit": 2
  },
  {
    "Entity": `COMOROS (THE)`,
    "Currency": `Comorian Franc`,
    "AlphabeticCode": `KMF`,
    "NumericCode": 174,
    "MinorUnit": 0
  },
  {
    "Entity": `CONGO (THE DEMOCRATIC REPUBLIC OF THE)`,
    "Currency": `Congolese Franc`,
    "AlphabeticCode": `CDF`,
    "NumericCode": 976,
    "MinorUnit": 2
  },
  {
    "Entity": `CONGO (THE)`,
    "Currency": `CFA Franc BEAC`,
    "AlphabeticCode": `XAF`,
    "NumericCode": 950,
    "MinorUnit": 0
  },
  {
    "Entity": `COOK ISLANDS (THE)`,
    "Currency": `New Zealand Dollar`,
    "AlphabeticCode": `NZD`,
    "NumericCode": 554,
    "MinorUnit": 2
  },
  {
    "Entity": `COSTA RICA`,
    "Currency": `Costa Rican Colon`,
    "AlphabeticCode": `CRC`,
    "NumericCode": 188,
    "MinorUnit": 2
  },
  {
    "Entity": `CÔTE D'IVOIRE`,
    "Currency": `CFA Franc BCEAO`,
    "AlphabeticCode": `XOF`,
    "NumericCode": 952,
    "MinorUnit": 0
  },
  {
    "Entity": `CROATIA`,
    "Currency": `Kuna`,
    "AlphabeticCode": `HRK`,
    "NumericCode": 191,
    "MinorUnit": 2
  },
  {
    "Entity": `CUBA`,
    "Currency": `Cuban Peso`,
    "AlphabeticCode": `CUP`,
    "NumericCode": 192,
    "MinorUnit": 2
  },
  {
    "Entity": `CUBA`,
    "Currency": `Peso Convertible`,
    "AlphabeticCode": `CUC`,
    "NumericCode": 931,
    "MinorUnit": 2
  },
  {
    "Entity": `CURAÇAO`,
    "Currency": `Netherlands Antillean Guilder`,
    "AlphabeticCode": `ANG`,
    "NumericCode": 532,
    "MinorUnit": 2
  },
  {
    "Entity": `CYPRUS`,
    "Currency": `Euro`,
    "AlphabeticCode": `EUR`,
    "NumericCode": 978,
    "MinorUnit": 2
  },
  {
    "Entity": `CZECHIA`,
    "Currency": `Czech Koruna`,
    "AlphabeticCode": `CZK`,
    "NumericCode": 203,
    "MinorUnit": 2
  },
  {
    "Entity": `DENMARK`,
    "Currency": `Danish Krone`,
    "AlphabeticCode": `DKK`,
    "NumericCode": 208,
    "MinorUnit": 2
  },
  {
    "Entity": `DJIBOUTI`,
    "Currency": `Djibouti Franc`,
    "AlphabeticCode": `DJF`,
    "NumericCode": 262,
    "MinorUnit": 0
  },
  {
    "Entity": `DOMINICA`,
    "Currency": `East Caribbean Dollar`,
    "AlphabeticCode": `XCD`,
    "NumericCode": 951,
    "MinorUnit": 2
  },
  {
    "Entity": `DOMINICAN REPUBLIC (THE)`,
    "Currency": `Dominican Peso`,
    "AlphabeticCode": `DOP`,
    "NumericCode": 214,
    "MinorUnit": 2
  },
  {
    "Entity": `ECUADOR`,
    "Currency": `US Dollar`,
    "AlphabeticCode": `USD`,
    "NumericCode": 840,
    "MinorUnit": 2
  },
  {
    "Entity": `EGYPT`,
    "Currency": `Egyptian Pound`,
    "AlphabeticCode": `EGP`,
    "NumericCode": 818,
    "MinorUnit": 2
  },
  {
    "Entity": `EL SALVADOR`,
    "Currency": `El Salvador Colon`,
    "AlphabeticCode": `SVC`,
    "NumericCode": 222,
    "MinorUnit": 2
  },
  {
    "Entity": `EL SALVADOR`,
    "Currency": `US Dollar`,
    "AlphabeticCode": `USD`,
    "NumericCode": 840,
    "MinorUnit": 2
  },
  {
    "Entity": `EQUATORIAL GUINEA`,
    "Currency": `CFA Franc BEAC`,
    "AlphabeticCode": `XAF`,
    "NumericCode": 950,
    "MinorUnit": 0
  },
  {
    "Entity": `ERITREA`,
    "Currency": `Nakfa`,
    "AlphabeticCode": `ERN`,
    "NumericCode": 232,
    "MinorUnit": 2
  },
  {
    "Entity": `ESTONIA`,
    "Currency": `Euro`,
    "AlphabeticCode": `EUR`,
    "NumericCode": 978,
    "MinorUnit": 2
  },
  {
    "Entity": `ESWATINI`,
    "Currency": `Lilangeni`,
    "AlphabeticCode": `SZL`,
    "NumericCode": 748,
    "MinorUnit": 2
  },
  {
    "Entity": `ETHIOPIA`,
    "Currency": `Ethiopian Birr`,
    "AlphabeticCode": `ETB`,
    "NumericCode": 230,
    "MinorUnit": 2
  },
  {
    "Entity": `EUROPEAN UNION`,
    "Currency": `Euro`,
    "AlphabeticCode": `EUR`,
    "NumericCode": 978,
    "MinorUnit": 2
  },
  {
    "Entity": `FALKLAND ISLANDS (THE) [MALVINAS]`,
    "Currency": `Falkland Islands Pound`,
    "AlphabeticCode": `FKP`,
    "NumericCode": 238,
    "MinorUnit": 2
  },
  {
    "Entity": `FAROE ISLANDS (THE)`,
    "Currency": `Danish Krone`,
    "AlphabeticCode": `DKK`,
    "NumericCode": 208,
    "MinorUnit": 2
  },
  {
    "Entity": `FIJI`,
    "Currency": `Fiji Dollar`,
    "AlphabeticCode": `FJD`,
    "NumericCode": 242,
    "MinorUnit": 2
  },
  {
    "Entity": `FINLAND`,
    "Currency": `Euro`,
    "AlphabeticCode": `EUR`,
    "NumericCode": 978,
    "MinorUnit": 2
  },
  {
    "Entity": `FRANCE`,
    "Currency": `Euro`,
    "AlphabeticCode": `EUR`,
    "NumericCode": 978,
    "MinorUnit": 2
  },
  {
    "Entity": `FRENCH GUIANA`,
    "Currency": `Euro`,
    "AlphabeticCode": `EUR`,
    "NumericCode": 978,
    "MinorUnit": 2
  },
  {
    "Entity": `FRENCH POLYNESIA`,
    "Currency": `CFP Franc`,
    "AlphabeticCode": `XPF`,
    "NumericCode": 953,
    "MinorUnit": 0
  },
  {
    "Entity": `FRENCH SOUTHERN TERRITORIES (THE)`,
    "Currency": `Euro`,
    "AlphabeticCode": `EUR`,
    "NumericCode": 978,
    "MinorUnit": 2
  },
  {
    "Entity": `GABON`,
    "Currency": `CFA Franc BEAC`,
    "AlphabeticCode": `XAF`,
    "NumericCode": 950,
    "MinorUnit": 0
  },
  {
    "Entity": `GAMBIA (THE)`,
    "Currency": `Dalasi`,
    "AlphabeticCode": `GMD`,
    "NumericCode": 270,
    "MinorUnit": 2
  },
  {
    "Entity": `GEORGIA`,
    "Currency": `Lari`,
    "AlphabeticCode": `GEL`,
    "NumericCode": 981,
    "MinorUnit": 2
  },
  {
    "Entity": `GERMANY`,
    "Currency": `Euro`,
    "AlphabeticCode": `EUR`,
    "NumericCode": 978,
    "MinorUnit": 2
  },
  {
    "Entity": `GHANA`,
    "Currency": `Ghana Cedi`,
    "AlphabeticCode": `GHS`,
    "NumericCode": 936,
    "MinorUnit": 2
  },
  {
    "Entity": `GIBRALTAR`,
    "Currency": `Gibraltar Pound`,
    "AlphabeticCode": `GIP`,
    "NumericCode": 292,
    "MinorUnit": 2
  },
  {
    "Entity": `GREECE`,
    "Currency": `Euro`,
    "AlphabeticCode": `EUR`,
    "NumericCode": 978,
    "MinorUnit": 2
  },
  {
    "Entity": `GREENLAND`,
    "Currency": `Danish Krone`,
    "AlphabeticCode": `DKK`,
    "NumericCode": 208,
    "MinorUnit": 2
  },
  {
    "Entity": `GRENADA`,
    "Currency": `East Caribbean Dollar`,
    "AlphabeticCode": `XCD`,
    "NumericCode": 951,
    "MinorUnit": 2
  },
  {
    "Entity": `GUADELOUPE`,
    "Currency": `Euro`,
    "AlphabeticCode": `EUR`,
    "NumericCode": 978,
    "MinorUnit": 2
  },
  {
    "Entity": `GUAM`,
    "Currency": `US Dollar`,
    "AlphabeticCode": `USD`,
    "NumericCode": 840,
    "MinorUnit": 2
  },
  {
    "Entity": `GUATEMALA`,
    "Currency": `Quetzal`,
    "AlphabeticCode": `GTQ`,
    "NumericCode": 320,
    "MinorUnit": 2
  },
  {
    "Entity": `GUERNSEY`,
    "Currency": `Pound Sterling`,
    "AlphabeticCode": `GBP`,
    "NumericCode": 826,
    "MinorUnit": 2
  },
  {
    "Entity": `GUINEA`,
    "Currency": `Guinean Franc`,
    "AlphabeticCode": `GNF`,
    "NumericCode": 324,
    "MinorUnit": 0
  },
  {
    "Entity": `GUINEA-BISSAU`,
    "Currency": `CFA Franc BCEAO`,
    "AlphabeticCode": `XOF`,
    "NumericCode": 952,
    "MinorUnit": 0
  },
  {
    "Entity": `GUYANA`,
    "Currency": `Guyana Dollar`,
    "AlphabeticCode": `GYD`,
    "NumericCode": 328,
    "MinorUnit": 2
  },
  {
    "Entity": `HAITI`,
    "Currency": `Gourde`,
    "AlphabeticCode": `HTG`,
    "NumericCode": 332,
    "MinorUnit": 2
  },
  {
    "Entity": `HAITI`,
    "Currency": `US Dollar`,
    "AlphabeticCode": `USD`,
    "NumericCode": 840,
    "MinorUnit": 2
  },
  {
    "Entity": `HEARD ISLAND AND McDONALD ISLANDS`,
    "Currency": `Australian Dollar`,
    "AlphabeticCode": `AUD`,
    "NumericCode": 36,
    "MinorUnit": 2
  },
  {
    "Entity": `HOLY SEE (THE)`,
    "Currency": `Euro`,
    "AlphabeticCode": `EUR`,
    "NumericCode": 978,
    "MinorUnit": 2
  },
  {
    "Entity": `HONDURAS`,
    "Currency": `Lempira`,
    "AlphabeticCode": `HNL`,
    "NumericCode": 340,
    "MinorUnit": 2
  },
  {
    "Entity": `HONG KONG`,
    "Currency": `Hong Kong Dollar`,
    "AlphabeticCode": `HKD`,
    "NumericCode": 344,
    "MinorUnit": 2
  },
  {
    "Entity": `HUNGARY`,
    "Currency": `Forint`,
    "AlphabeticCode": `HUF`,
    "NumericCode": 348,
    "MinorUnit": 2
  },
  {
    "Entity": `ICELAND`,
    "Currency": `Iceland Krona`,
    "AlphabeticCode": `ISK`,
    "NumericCode": 352,
    "MinorUnit": 0
  },
  {
    "Entity": `INDIA`,
    "Currency": `Indian Rupee`,
    "AlphabeticCode": `INR`,
    "NumericCode": 356,
    "MinorUnit": 2
  },
  {
    "Entity": `INDONESIA`,
    "Currency": `Rupiah`,
    "AlphabeticCode": `IDR`,
    "NumericCode": 360,
    "MinorUnit": 2
  },
  {
    "Entity": `INTERNATIONAL MONETARY FUND (IMF)`,
    "Currency": `SDR (Special Drawing Right)`,
    "AlphabeticCode": `XDR`,
    "NumericCode": 960,
    "MinorUnit": `N.A.`
  },
  {
    "Entity": `IRAN (ISLAMIC REPUBLIC OF)`,
    "Currency": `Iranian Rial`,
    "AlphabeticCode": `IRR`,
    "NumericCode": 364,
    "MinorUnit": 2
  },
  {
    "Entity": `IRAQ`,
    "Currency": `Iraqi Dinar`,
    "AlphabeticCode": `IQD`,
    "NumericCode": 368,
    "MinorUnit": 3
  },
  {
    "Entity": `IRELAND`,
    "Currency": `Euro`,
    "AlphabeticCode": `EUR`,
    "NumericCode": 978,
    "MinorUnit": 2
  },
  {
    "Entity": `ISLE OF MAN`,
    "Currency": `Pound Sterling`,
    "AlphabeticCode": `GBP`,
    "NumericCode": 826,
    "MinorUnit": 2
  },
  {
    "Entity": `ISRAEL`,
    "Currency": `New Israeli Sheqel`,
    "AlphabeticCode": `ILS`,
    "NumericCode": 376,
    "MinorUnit": 2
  },
  {
    "Entity": `ITALY`,
    "Currency": `Euro`,
    "AlphabeticCode": `EUR`,
    "NumericCode": 978,
    "MinorUnit": 2
  },
  {
    "Entity": `JAMAICA`,
    "Currency": `Jamaican Dollar`,
    "AlphabeticCode": `JMD`,
    "NumericCode": 388,
    "MinorUnit": 2
  },
  {
    "Entity": `JAPAN`,
    "Currency": `Yen`,
    "AlphabeticCode": `JPY`,
    "NumericCode": 392,
    "MinorUnit": 0
  },
  {
    "Entity": `JERSEY`,
    "Currency": `Pound Sterling`,
    "AlphabeticCode": `GBP`,
    "NumericCode": 826,
    "MinorUnit": 2
  },
  {
    "Entity": `JORDAN`,
    "Currency": `Jordanian Dinar`,
    "AlphabeticCode": `JOD`,
    "NumericCode": 400,
    "MinorUnit": 3
  },
  {
    "Entity": `KAZAKHSTAN`,
    "Currency": `Tenge`,
    "AlphabeticCode": `KZT`,
    "NumericCode": 398,
    "MinorUnit": 2
  },
  {
    "Entity": `KENYA`,
    "Currency": `Kenyan Shilling`,
    "AlphabeticCode": `KES`,
    "NumericCode": 404,
    "MinorUnit": 2
  },
  {
    "Entity": `KIRIBATI`,
    "Currency": `Australian Dollar`,
    "AlphabeticCode": `AUD`,
    "NumericCode": 36,
    "MinorUnit": 2
  },
  {
    "Entity": `KOREA (THE DEMOCRATIC PEOPLE’S REPUBLIC OF)`,
    "Currency": `North Korean Won`,
    "AlphabeticCode": `KPW`,
    "NumericCode": 408,
    "MinorUnit": 2
  },
  {
    "Entity": `KOREA (THE REPUBLIC OF)`,
    "Currency": `Won`,
    "AlphabeticCode": `KRW`,
    "NumericCode": 410,
    "MinorUnit": 0
  },
  {
    "Entity": `KUWAIT`,
    "Currency": `Kuwaiti Dinar`,
    "AlphabeticCode": `KWD`,
    "NumericCode": 414,
    "MinorUnit": 3
  },
  {
    "Entity": `KYRGYZSTAN`,
    "Currency": `Som`,
    "AlphabeticCode": `KGS`,
    "NumericCode": 417,
    "MinorUnit": 2
  },
  {
    "Entity": `LAO PEOPLE’S DEMOCRATIC REPUBLIC (THE)`,
    "Currency": `Lao Kip`,
    "AlphabeticCode": `LAK`,
    "NumericCode": 418,
    "MinorUnit": 2
  },
  {
    "Entity": `LATVIA`,
    "Currency": `Euro`,
    "AlphabeticCode": `EUR`,
    "NumericCode": 978,
    "MinorUnit": 2
  },
  {
    "Entity": `LEBANON`,
    "Currency": `Lebanese Pound`,
    "AlphabeticCode": `LBP`,
    "NumericCode": 422,
    "MinorUnit": 2
  },
  {
    "Entity": `LESOTHO`,
    "Currency": `Loti`,
    "AlphabeticCode": `LSL`,
    "NumericCode": 426,
    "MinorUnit": 2
  },
  {
    "Entity": `LESOTHO`,
    "Currency": `Rand`,
    "AlphabeticCode": `ZAR`,
    "NumericCode": 710,
    "MinorUnit": 2
  },
  {
    "Entity": `LIBERIA`,
    "Currency": `Liberian Dollar`,
    "AlphabeticCode": `LRD`,
    "NumericCode": 430,
    "MinorUnit": 2
  },
  {
    "Entity": `LIBYA`,
    "Currency": `Libyan Dinar`,
    "AlphabeticCode": `LYD`,
    "NumericCode": 434,
    "MinorUnit": 3
  },
  {
    "Entity": `LIECHTENSTEIN`,
    "Currency": `Swiss Franc`,
    "AlphabeticCode": `CHF`,
    "NumericCode": 756,
    "MinorUnit": 2
  },
  {
    "Entity": `LITHUANIA`,
    "Currency": `Euro`,
    "AlphabeticCode": `EUR`,
    "NumericCode": 978,
    "MinorUnit": 2
  },
  {
    "Entity": `LUXEMBOURG`,
    "Currency": `Euro`,
    "AlphabeticCode": `EUR`,
    "NumericCode": 978,
    "MinorUnit": 2
  },
  {
    "Entity": `MACAO`,
    "Currency": `Pataca`,
    "AlphabeticCode": `MOP`,
    "NumericCode": 446,
    "MinorUnit": 2
  },
  {
    "Entity": `MADAGASCAR`,
    "Currency": `Malagasy Ariary`,
    "AlphabeticCode": `MGA`,
    "NumericCode": 969,
    "MinorUnit": 2
  },
  {
    "Entity": `MALAWI`,
    "Currency": `Malawi Kwacha`,
    "AlphabeticCode": `MWK`,
    "NumericCode": 454,
    "MinorUnit": 2
  },
  {
    "Entity": `MALAYSIA`,
    "Currency": `Malaysian Ringgit`,
    "AlphabeticCode": `MYR`,
    "NumericCode": 458,
    "MinorUnit": 2
  },
  {
    "Entity": `MALDIVES`,
    "Currency": `Rufiyaa`,
    "AlphabeticCode": `MVR`,
    "NumericCode": 462,
    "MinorUnit": 2
  },
  {
    "Entity": `MALI`,
    "Currency": `CFA Franc BCEAO`,
    "AlphabeticCode": `XOF`,
    "NumericCode": 952,
    "MinorUnit": 0
  },
  {
    "Entity": `MALTA`,
    "Currency": `Euro`,
    "AlphabeticCode": `EUR`,
    "NumericCode": 978,
    "MinorUnit": 2
  },
  {
    "Entity": `MARSHALL ISLANDS (THE)`,
    "Currency": `US Dollar`,
    "AlphabeticCode": `USD`,
    "NumericCode": 840,
    "MinorUnit": 2
  },
  {
    "Entity": `MARTINIQUE`,
    "Currency": `Euro`,
    "AlphabeticCode": `EUR`,
    "NumericCode": 978,
    "MinorUnit": 2
  },
  {
    "Entity": `MAURITANIA`,
    "Currency": `Ouguiya`,
    "AlphabeticCode": `MRU`,
    "NumericCode": 929,
    "MinorUnit": 2
  },
  {
    "Entity": `MAURITIUS`,
    "Currency": `Mauritius Rupee`,
    "AlphabeticCode": `MUR`,
    "NumericCode": 480,
    "MinorUnit": 2
  },
  {
    "Entity": `MAYOTTE`,
    "Currency": `Euro`,
    "AlphabeticCode": `EUR`,
    "NumericCode": 978,
    "MinorUnit": 2
  },
  {
    "Entity": `MEMBER COUNTRIES OF THE AFRICAN DEVELOPMENT BANK GROUP`,
    "Currency": `ADB Unit of Account`,
    "AlphabeticCode": `XUA`,
    "NumericCode": 965,
    "MinorUnit": `N.A.`
  },
  {
    "Entity": `MEXICO`,
    "Currency": `Mexican Peso`,
    "AlphabeticCode": `MXN`,
    "NumericCode": 484,
    "MinorUnit": 2
  },
  {
    "Entity": `MICRONESIA (FEDERATED STATES OF)`,
    "Currency": `US Dollar`,
    "AlphabeticCode": `USD`,
    "NumericCode": 840,
    "MinorUnit": 2
  },
  {
    "Entity": `MOLDOVA (THE REPUBLIC OF)`,
    "Currency": `Moldovan Leu`,
    "AlphabeticCode": `MDL`,
    "NumericCode": 498,
    "MinorUnit": 2
  },
  {
    "Entity": `MONACO`,
    "Currency": `Euro`,
    "AlphabeticCode": `EUR`,
    "NumericCode": 978,
    "MinorUnit": 2
  },
  {
    "Entity": `MONGOLIA`,
    "Currency": `Tugrik`,
    "AlphabeticCode": `MNT`,
    "NumericCode": 496,
    "MinorUnit": 2
  },
  {
    "Entity": `MONTENEGRO`,
    "Currency": `Euro`,
    "AlphabeticCode": `EUR`,
    "NumericCode": 978,
    "MinorUnit": 2
  },
  {
    "Entity": `MONTSERRAT`,
    "Currency": `East Caribbean Dollar`,
    "AlphabeticCode": `XCD`,
    "NumericCode": 951,
    "MinorUnit": 2
  },
  {
    "Entity": `MOROCCO`,
    "Currency": `Moroccan Dirham`,
    "AlphabeticCode": `MAD`,
    "NumericCode": 504,
    "MinorUnit": 2
  },
  {
    "Entity": `MOZAMBIQUE`,
    "Currency": `Mozambique Metical`,
    "AlphabeticCode": `MZN`,
    "NumericCode": 943,
    "MinorUnit": 2
  },
  {
    "Entity": `MYANMAR`,
    "Currency": `Kyat`,
    "AlphabeticCode": `MMK`,
    "NumericCode": 104,
    "MinorUnit": 2
  },
  {
    "Entity": `NAMIBIA`,
    "Currency": `Namibia Dollar`,
    "AlphabeticCode": `NAD`,
    "NumericCode": 516,
    "MinorUnit": 2
  },
  {
    "Entity": `NAMIBIA`,
    "Currency": `Rand`,
    "AlphabeticCode": `ZAR`,
    "NumericCode": 710,
    "MinorUnit": 2
  },
  {
    "Entity": `NAURU`,
    "Currency": `Australian Dollar`,
    "AlphabeticCode": `AUD`,
    "NumericCode": 36,
    "MinorUnit": 2
  },
  {
    "Entity": `NEPAL`,
    "Currency": `Nepalese Rupee`,
    "AlphabeticCode": `NPR`,
    "NumericCode": 524,
    "MinorUnit": 2
  },
  {
    "Entity": `NETHERLANDS (THE)`,
    "Currency": `Euro`,
    "AlphabeticCode": `EUR`,
    "NumericCode": 978,
    "MinorUnit": 2
  },
  {
    "Entity": `NEW CALEDONIA`,
    "Currency": `CFP Franc`,
    "AlphabeticCode": `XPF`,
    "NumericCode": 953,
    "MinorUnit": 0
  },
  {
    "Entity": `NEW ZEALAND`,
    "Currency": `New Zealand Dollar`,
    "AlphabeticCode": `NZD`,
    "NumericCode": 554,
    "MinorUnit": 2
  },
  {
    "Entity": `NICARAGUA`,
    "Currency": `Cordoba Oro`,
    "AlphabeticCode": `NIO`,
    "NumericCode": 558,
    "MinorUnit": 2
  },
  {
    "Entity": `NIGER (THE)`,
    "Currency": `CFA Franc BCEAO`,
    "AlphabeticCode": `XOF`,
    "NumericCode": 952,
    "MinorUnit": 0
  },
  {
    "Entity": `NIGERIA`,
    "Currency": `Naira`,
    "AlphabeticCode": `NGN`,
    "NumericCode": 566,
    "MinorUnit": 2
  },
  {
    "Entity": `NIUE`,
    "Currency": `New Zealand Dollar`,
    "AlphabeticCode": `NZD`,
    "NumericCode": 554,
    "MinorUnit": 2
  },
  {
    "Entity": `NORFOLK ISLAND`,
    "Currency": `Australian Dollar`,
    "AlphabeticCode": `AUD`,
    "NumericCode": 36,
    "MinorUnit": 2
  },
  {
    "Entity": `NORTH MACEDONIA`,
    "Currency": `Denar`,
    "AlphabeticCode": `MKD`,
    "NumericCode": 807,
    "MinorUnit": 2
  },
  {
    "Entity": `NORTHERN MARIANA ISLANDS (THE)`,
    "Currency": `US Dollar`,
    "AlphabeticCode": `USD`,
    "NumericCode": 840,
    "MinorUnit": 2
  },
  {
    "Entity": `NORWAY`,
    "Currency": `Norwegian Krone`,
    "AlphabeticCode": `NOK`,
    "NumericCode": 578,
    "MinorUnit": 2
  },
  {
    "Entity": `OMAN`,
    "Currency": `Rial Omani`,
    "AlphabeticCode": `OMR`,
    "NumericCode": 512,
    "MinorUnit": 3
  },
  {
    "Entity": `PAKISTAN`,
    "Currency": `Pakistan Rupee`,
    "AlphabeticCode": `PKR`,
    "NumericCode": 586,
    "MinorUnit": 2
  },
  {
    "Entity": `PALAU`,
    "Currency": `US Dollar`,
    "AlphabeticCode": `USD`,
    "NumericCode": 840,
    "MinorUnit": 2
  },
  {
    "Entity": `PALESTINE, STATE OF`,
    "Currency": `No universal currency`,
    "AlphabeticCode": ``,
    "NumericCode": ``,
    "MinorUnit": ``
  },
  {
    "Entity": `PANAMA`,
    "Currency": `Balboa`,
    "AlphabeticCode": `PAB`,
    "NumericCode": 590,
    "MinorUnit": 2
  },
  {
    "Entity": `PANAMA`,
    "Currency": `US Dollar`,
    "AlphabeticCode": `USD`,
    "NumericCode": 840,
    "MinorUnit": 2
  },
  {
    "Entity": `PAPUA NEW GUINEA`,
    "Currency": `Kina`,
    "AlphabeticCode": `PGK`,
    "NumericCode": 598,
    "MinorUnit": 2
  },
  {
    "Entity": `PARAGUAY`,
    "Currency": `Guarani`,
    "AlphabeticCode": `PYG`,
    "NumericCode": 600,
    "MinorUnit": 0
  },
  {
    "Entity": `PERU`,
    "Currency": `Sol`,
    "AlphabeticCode": `PEN`,
    "NumericCode": 604,
    "MinorUnit": 2
  },
  {
    "Entity": `PHILIPPINES (THE)`,
    "Currency": `Philippine Peso`,
    "AlphabeticCode": `PHP`,
    "NumericCode": 608,
    "MinorUnit": 2
  },
  {
    "Entity": `PITCAIRN`,
    "Currency": `New Zealand Dollar`,
    "AlphabeticCode": `NZD`,
    "NumericCode": 554,
    "MinorUnit": 2
  },
  {
    "Entity": `POLAND`,
    "Currency": `Zloty`,
    "AlphabeticCode": `PLN`,
    "NumericCode": 985,
    "MinorUnit": 2
  },
  {
    "Entity": `PORTUGAL`,
    "Currency": `Euro`,
    "AlphabeticCode": `EUR`,
    "NumericCode": 978,
    "MinorUnit": 2
  },
  {
    "Entity": `PUERTO RICO`,
    "Currency": `US Dollar`,
    "AlphabeticCode": `USD`,
    "NumericCode": 840,
    "MinorUnit": 2
  },
  {
    "Entity": `QATAR`,
    "Currency": `Qatari Rial`,
    "AlphabeticCode": `QAR`,
    "NumericCode": 634,
    "MinorUnit": 2
  },
  {
    "Entity": `RÉUNION`,
    "Currency": `Euro`,
    "AlphabeticCode": `EUR`,
    "NumericCode": 978,
    "MinorUnit": 2
  },
  {
    "Entity": `ROMANIA`,
    "Currency": `Romanian Leu`,
    "AlphabeticCode": `RON`,
    "NumericCode": 946,
    "MinorUnit": 2
  },
  {
    "Entity": `RUSSIAN FEDERATION (THE)`,
    "Currency": `Russian Ruble`,
    "AlphabeticCode": `RUB`,
    "NumericCode": 643,
    "MinorUnit": 2
  },
  {
    "Entity": `RWANDA`,
    "Currency": `Rwanda Franc`,
    "AlphabeticCode": `RWF`,
    "NumericCode": 646,
    "MinorUnit": 0
  },
  {
    "Entity": `SAINT BARTHÉLEMY`,
    "Currency": `Euro`,
    "AlphabeticCode": `EUR`,
    "NumericCode": 978,
    "MinorUnit": 2
  },
  {
    "Entity": `SAINT HELENA, ASCENSION AND TRISTAN DA CUNHA`,
    "Currency": `Saint Helena Pound`,
    "AlphabeticCode": `SHP`,
    "NumericCode": 654,
    "MinorUnit": 2
  },
  {
    "Entity": `SAINT KITTS AND NEVIS`,
    "Currency": `East Caribbean Dollar`,
    "AlphabeticCode": `XCD`,
    "NumericCode": 951,
    "MinorUnit": 2
  },
  {
    "Entity": `SAINT LUCIA`,
    "Currency": `East Caribbean Dollar`,
    "AlphabeticCode": `XCD`,
    "NumericCode": 951,
    "MinorUnit": 2
  },
  {
    "Entity": `SAINT MARTIN (FRENCH PART)`,
    "Currency": `Euro`,
    "AlphabeticCode": `EUR`,
    "NumericCode": 978,
    "MinorUnit": 2
  },
  {
    "Entity": `SAINT PIERRE AND MIQUELON`,
    "Currency": `Euro`,
    "AlphabeticCode": `EUR`,
    "NumericCode": 978,
    "MinorUnit": 2
  },
  {
    "Entity": `SAINT VINCENT AND THE GRENADINES`,
    "Currency": `East Caribbean Dollar`,
    "AlphabeticCode": `XCD`,
    "NumericCode": 951,
    "MinorUnit": 2
  },
  {
    "Entity": `SAMOA`,
    "Currency": `Tala`,
    "AlphabeticCode": `WST`,
    "NumericCode": 882,
    "MinorUnit": 2
  },
  {
    "Entity": `SAN MARINO`,
    "Currency": `Euro`,
    "AlphabeticCode": `EUR`,
    "NumericCode": 978,
    "MinorUnit": 2
  },
  {
    "Entity": `SAO TOME AND PRINCIPE`,
    "Currency": `Dobra`,
    "AlphabeticCode": `STN`,
    "NumericCode": 930,
    "MinorUnit": 2
  },
  {
    "Entity": `SAUDI ARABIA`,
    "Currency": `Saudi Riyal`,
    "AlphabeticCode": `SAR`,
    "NumericCode": 682,
    "MinorUnit": 2
  },
  {
    "Entity": `SENEGAL`,
    "Currency": `CFA Franc BCEAO`,
    "AlphabeticCode": `XOF`,
    "NumericCode": 952,
    "MinorUnit": 0
  },
  {
    "Entity": `SERBIA`,
    "Currency": `Serbian Dinar`,
    "AlphabeticCode": `RSD`,
    "NumericCode": 941,
    "MinorUnit": 2
  },
  {
    "Entity": `SEYCHELLES`,
    "Currency": `Seychelles Rupee`,
    "AlphabeticCode": `SCR`,
    "NumericCode": 690,
    "MinorUnit": 2
  },
  {
    "Entity": `SIERRA LEONE`,
    "Currency": `Leone`,
    "AlphabeticCode": `SLL`,
    "NumericCode": 694,
    "MinorUnit": 2
  },
  {
    "Entity": `SINGAPORE`,
    "Currency": `Singapore Dollar`,
    "AlphabeticCode": `SGD`,
    "NumericCode": 702,
    "MinorUnit": 2
  },
  {
    "Entity": `SINT MAARTEN (DUTCH PART)`,
    "Currency": `Netherlands Antillean Guilder`,
    "AlphabeticCode": `ANG`,
    "NumericCode": 532,
    "MinorUnit": 2
  },
  {
    "Entity": `SISTEMA UNITARIO DE COMPENSACION REGIONAL DE PAGOS SUCRE`,
    "Currency": `Sucre`,
    "AlphabeticCode": `XSU`,
    "NumericCode": 994,
    "MinorUnit": `N.A.`
  },
  {
    "Entity": `SLOVAKIA`,
    "Currency": `Euro`,
    "AlphabeticCode": `EUR`,
    "NumericCode": 978,
    "MinorUnit": 2
  },
  {
    "Entity": `SLOVENIA`,
    "Currency": `Euro`,
    "AlphabeticCode": `EUR`,
    "NumericCode": 978,
    "MinorUnit": 2
  },
  {
    "Entity": `SOLOMON ISLANDS`,
    "Currency": `Solomon Islands Dollar`,
    "AlphabeticCode": `SBD`,
    "NumericCode": 90,
    "MinorUnit": 2
  },
  {
    "Entity": `SOMALIA`,
    "Currency": `Somali Shilling`,
    "AlphabeticCode": `SOS`,
    "NumericCode": 706,
    "MinorUnit": 2
  },
  {
    "Entity": `SOUTH AFRICA`,
    "Currency": `Rand`,
    "AlphabeticCode": `ZAR`,
    "NumericCode": 710,
    "MinorUnit": 2
  },
  {
    "Entity": `SOUTH GEORGIA AND THE SOUTH SANDWICH ISLANDS`,
    "Currency": `No universal currency`,
    "AlphabeticCode": ``,
    "NumericCode": ``,
    "MinorUnit": ``
  },
  {
    "Entity": `SOUTH SUDAN`,
    "Currency": `South Sudanese Pound`,
    "AlphabeticCode": `SSP`,
    "NumericCode": 728,
    "MinorUnit": 2
  },
  {
    "Entity": `SPAIN`,
    "Currency": `Euro`,
    "AlphabeticCode": `EUR`,
    "NumericCode": 978,
    "MinorUnit": 2
  },
  {
    "Entity": `SRI LANKA`,
    "Currency": `Sri Lanka Rupee`,
    "AlphabeticCode": `LKR`,
    "NumericCode": 144,
    "MinorUnit": 2
  },
  {
    "Entity": `SUDAN (THE)`,
    "Currency": `Sudanese Pound`,
    "AlphabeticCode": `SDG`,
    "NumericCode": 938,
    "MinorUnit": 2
  },
  {
    "Entity": `SURINAME`,
    "Currency": `Surinam Dollar`,
    "AlphabeticCode": `SRD`,
    "NumericCode": 968,
    "MinorUnit": 2
  },
  {
    "Entity": `SVALBARD AND JAN MAYEN`,
    "Currency": `Norwegian Krone`,
    "AlphabeticCode": `NOK`,
    "NumericCode": 578,
    "MinorUnit": 2
  },
  {
    "Entity": `SWEDEN`,
    "Currency": `Swedish Krona`,
    "AlphabeticCode": `SEK`,
    "NumericCode": 752,
    "MinorUnit": 2
  },
  {
    "Entity": `SWITZERLAND`,
    "Currency": `Swiss Franc`,
    "AlphabeticCode": `CHF`,
    "NumericCode": 756,
    "MinorUnit": 2
  },
  {
    "Entity": `SYRIAN ARAB REPUBLIC`,
    "Currency": `Syrian Pound`,
    "AlphabeticCode": `SYP`,
    "NumericCode": 760,
    "MinorUnit": 2
  },
  {
    "Entity": `TAIWAN (PROVINCE OF CHINA)`,
    "Currency": `New Taiwan Dollar`,
    "AlphabeticCode": `TWD`,
    "NumericCode": 901,
    "MinorUnit": 2
  },
  {
    "Entity": `TAJIKISTAN`,
    "Currency": `Somoni`,
    "AlphabeticCode": `TJS`,
    "NumericCode": 972,
    "MinorUnit": 2
  },
  {
    "Entity": `TANZANIA, UNITED REPUBLIC OF`,
    "Currency": `Tanzanian Shilling`,
    "AlphabeticCode": `TZS`,
    "NumericCode": 834,
    "MinorUnit": 2
  },
  {
    "Entity": `THAILAND`,
    "Currency": `Baht`,
    "AlphabeticCode": `THB`,
    "NumericCode": 764,
    "MinorUnit": 2
  },
  {
    "Entity": `TIMOR-LESTE`,
    "Currency": `US Dollar`,
    "AlphabeticCode": `USD`,
    "NumericCode": 840,
    "MinorUnit": 2
  },
  {
    "Entity": `TOGO`,
    "Currency": `CFA Franc BCEAO`,
    "AlphabeticCode": `XOF`,
    "NumericCode": 952,
    "MinorUnit": 0
  },
  {
    "Entity": `TOKELAU`,
    "Currency": `New Zealand Dollar`,
    "AlphabeticCode": `NZD`,
    "NumericCode": 554,
    "MinorUnit": 2
  },
  {
    "Entity": `TONGA`,
    "Currency": `Pa’anga`,
    "AlphabeticCode": `TOP`,
    "NumericCode": 776,
    "MinorUnit": 2
  },
  {
    "Entity": `TRINIDAD AND TOBAGO`,
    "Currency": `Trinidad and Tobago Dollar`,
    "AlphabeticCode": `TTD`,
    "NumericCode": 780,
    "MinorUnit": 2
  },
  {
    "Entity": `TUNISIA`,
    "Currency": `Tunisian Dinar`,
    "AlphabeticCode": `TND`,
    "NumericCode": 788,
    "MinorUnit": 3
  },
  {
    "Entity": `TURKEY`,
    "Currency": `Turkish Lira`,
    "AlphabeticCode": `TRY`,
    "NumericCode": 949,
    "MinorUnit": 2
  },
  {
    "Entity": `TURKMENISTAN`,
    "Currency": `Turkmenistan New Manat`,
    "AlphabeticCode": `TMT`,
    "NumericCode": 934,
    "MinorUnit": 2
  },
  {
    "Entity": `TURKS AND CAICOS ISLANDS (THE)`,
    "Currency": `US Dollar`,
    "AlphabeticCode": `USD`,
    "NumericCode": 840,
    "MinorUnit": 2
  },
  {
    "Entity": `TUVALU`,
    "Currency": `Australian Dollar`,
    "AlphabeticCode": `AUD`,
    "NumericCode": 36,
    "MinorUnit": 2
  },
  {
    "Entity": `UGANDA`,
    "Currency": `Uganda Shilling`,
    "AlphabeticCode": `UGX`,
    "NumericCode": 800,
    "MinorUnit": 0
  },
  {
    "Entity": `UKRAINE`,
    "Currency": `Hryvnia`,
    "AlphabeticCode": `UAH`,
    "NumericCode": 980,
    "MinorUnit": 2
  },
  {
    "Entity": `UNITED ARAB EMIRATES (THE)`,
    "Currency": `UAE Dirham`,
    "AlphabeticCode": `AED`,
    "NumericCode": 784,
    "MinorUnit": 2
  },
  {
    "Entity": `UNITED KINGDOM OF GREAT BRITAIN AND NORTHERN IRELAND (THE)`,
    "Currency": `Pound Sterling`,
    "AlphabeticCode": `GBP`,
    "NumericCode": 826,
    "MinorUnit": 2
  },
  {
    "Entity": `UNITED STATES MINOR OUTLYING ISLANDS (THE)`,
    "Currency": `US Dollar`,
    "AlphabeticCode": `USD`,
    "NumericCode": 840,
    "MinorUnit": 2
  },
  {
    "Entity": `UNITED STATES OF AMERICA (THE)`,
    "Currency": `US Dollar`,
    "AlphabeticCode": `USD`,
    "NumericCode": 840,
    "MinorUnit": 2
  },
  {
    "Entity": `URUGUAY`,
    "Currency": `Peso Uruguayo`,
    "AlphabeticCode": `UYU`,
    "NumericCode": 858,
    "MinorUnit": 2
  },
  {
    "Entity": `URUGUAY`,
    "Currency": `Unidad Previsional`,
    "AlphabeticCode": `UYW`,
    "NumericCode": 927,
    "MinorUnit": 4
  },
  {
    "Entity": `UZBEKISTAN`,
    "Currency": `Uzbekistan Sum`,
    "AlphabeticCode": `UZS`,
    "NumericCode": 860,
    "MinorUnit": 2
  },
  {
    "Entity": `VANUATU`,
    "Currency": `Vatu`,
    "AlphabeticCode": `VUV`,
    "NumericCode": 548,
    "MinorUnit": 0
  },
  {
    "Entity": `VENEZUELA (BOLIVARIAN REPUBLIC OF)`,
    "Currency": `Bolívar Soberano`,
    "AlphabeticCode": `VES`,
    "NumericCode": 928,
    "MinorUnit": 2
  },
  {
    "Entity": `VENEZUELA (BOLIVARIAN REPUBLIC OF)`,
    "Currency": `Bolívar Soberano`,
    "AlphabeticCode": `VED`,
    "NumericCode": 926,
    "MinorUnit": 2
  },
  {
    "Entity": `VIET NAM`,
    "Currency": `Dong`,
    "AlphabeticCode": `VND`,
    "NumericCode": 704,
    "MinorUnit": 0
  },
  {
    "Entity": `VIRGIN ISLANDS (BRITISH)`,
    "Currency": `US Dollar`,
    "AlphabeticCode": `USD`,
    "NumericCode": 840,
    "MinorUnit": 2
  },
  {
    "Entity": `VIRGIN ISLANDS (U.S.)`,
    "Currency": `US Dollar`,
    "AlphabeticCode": `USD`,
    "NumericCode": 840,
    "MinorUnit": 2
  },
  {
    "Entity": `WALLIS AND FUTUNA`,
    "Currency": `CFP Franc`,
    "AlphabeticCode": `XPF`,
    "NumericCode": 953,
    "MinorUnit": 0
  },
  {
    "Entity": `WESTERN SAHARA`,
    "Currency": `Moroccan Dirham`,
    "AlphabeticCode": `MAD`,
    "NumericCode": 504,
    "MinorUnit": 2
  },
  {
    "Entity": `YEMEN`,
    "Currency": `Yemeni Rial`,
    "AlphabeticCode": `YER`,
    "NumericCode": 886,
    "MinorUnit": 2
  },
  {
    "Entity": `ZAMBIA`,
    "Currency": `Zambian Kwacha`,
    "AlphabeticCode": `ZMW`,
    "NumericCode": 967,
    "MinorUnit": 2
  },
  {
    "Entity": `ZIMBABWE`,
    "Currency": `Zimbabwe Dollar`,
    "AlphabeticCode": `ZWL`,
    "NumericCode": 932,
    "MinorUnit": 2
  }
];

const AlphabeticCodes = currencies.map(item => item.AlphabeticCode);
const codeCompareFunction = (a,b) => (a.AlphabeticCode < b.AlphabeticCode) ? -1 : (a.AlphabeticCode > b.AlphabeticCode) ? 1 : 0;
const codeFilterFunction = ({ AlphabeticCode }, index) => AlphabeticCode.length === 3 && !AlphabeticCodes.includes(AlphabeticCode, index + 1);

export const currencyData = currencies.filter(codeFilterFunction).sort(codeCompareFunction);
