import {v1 as uuidv1} from "uuid";
import {useMutation, useQuery, useQueryClient} from "react-query";
import {useDispatch} from "react-redux";
import {
    createOrder,
    createPaypalPayment,
    getMyOrders,
    getMyShopOrders,
    getOrder,
    getOrderAvailablePaymentMethods,
    getOrderBillingAddress,
    getOrderCaptures,
    getOrderCustomer,
    getOrderMarket,
    getOrderPaymentSource,
    getOrderRefunds,
    getOrderShipments,
    getOrderShippingAddress,
    getPaypalPaymentByPaypalId,
    updateCapture,
    updateOrder,
    updatePaypalPayment,
    updateShipment
} from "./client";
import {addMessage} from "../alerts/messagesSlice";

const fiveMinutes = 1000 * 60 * 5;
const orderKeys = {
    all: [`orders`],
    lists: () => [...orderKeys.all, `list`],
    list: (filters) => [...orderKeys.lists(), { filters }],
    detail: (orderId) => [...orderKeys.all, `detail`, orderId],
    captures: (orderId) => [...orderKeys.detail(orderId), `captures`],
    customer: (orderId) => [...orderKeys.detail(orderId), `customer`],
    availablePaymentMethods: (orderId) => [...orderKeys.detail(orderId), `available_payment_methods`],
    billingAddress: (orderId) => [...orderKeys.detail(orderId), `billing_address`],
    lineItems: (orderId) => [...orderKeys.detail(orderId), `line_items`],
    market: (orderId) => [...orderKeys.detail(orderId), `market`],
    paymentSource: (orderId) => [...orderKeys.detail(orderId), `payment_source`],
    refunds: (orderId) => [...orderKeys.detail(orderId), `refunds`],
    shipments: (orderId) => [...orderKeys.detail(orderId), `shipments`],
    shippingAddress: (orderId) => [...orderKeys.detail(orderId), `shipping_address`],
}

const useMyOrders = ({ statuses }) => {
    const statusString = statuses.join(`,`);
    return useQuery(orderKeys.list({ status:statusString, shop:false }), () => getMyOrders({ status:statusString }), {enabled:!!statuses, staleTime:fiveMinutes});
}
const useMyShopOrders = ({ statuses = [] }) => {
    const statusString = statuses.join(`,`);
    return useQuery(orderKeys.list({ status:statusString, shop:true }), () => getMyShopOrders({ status:statusString }), {enabled:!!statuses, staleTime:fiveMinutes});
}
const useOrder = (orderId) => {
    return useQuery(orderKeys.detail(orderId), () => getOrder(orderId), {enabled:!!orderId, staleTime:fiveMinutes});
}
const useCreateOrder = () => {
    // Directus API checks for existing draft/pending order for this market/seller before creating a new one.
    const queryClient = useQueryClient();
    const dispatch = useDispatch();
    return useMutation(createOrder,{
        onError: (err) => {
            dispatch(addMessage({id:uuidv1(), text:`Failed creating order: ${err}`, priority: `low`}));
        },
        onSuccess: (data, variables) => {
            queryClient.invalidateQueries(orderKeys.all).then();
        }
    });
}

const useUpdateOrder = () => {
    const queryClient = useQueryClient();
    const dispatch = useDispatch();
    return useMutation(updateOrder, {
        onError: (err) => {
            const errors = JSON.parse(err.message).errors;
            dispatch(addMessage({id: uuidv1(), text: `Failed updating order: ${errors[0][`detail`]}`, priority: `low`}));
        },
        onSuccess: (data, variables) => {
            queryClient.invalidateQueries(orderKeys.all).then();
        }
    });
}

const useOrderAvailablePaymentMethods = (orderId) => {
    return useQuery(orderKeys.availablePaymentMethods(orderId), () => getOrderAvailablePaymentMethods(orderId), {enabled:!!orderId, staleTime:fiveMinutes});
}
const useOrderBillingAddress = (orderId) => {
    return useQuery(orderKeys.billingAddress(orderId), () => getOrderBillingAddress(orderId), {enabled:!!orderId, staleTime:fiveMinutes});
}
const useOrderCaptures = (orderId) => {
    return useQuery(orderKeys.captures(orderId), () => getOrderCaptures(orderId), {enabled:!!orderId, staleTime:fiveMinutes});
}
const useOrderCustomer = (orderId) => {
    return useQuery(orderKeys.customer(orderId), () => getOrderCustomer(orderId), {enabled:!!orderId, staleTime:fiveMinutes});
}
const useOrderMarket = (orderId) => {
    return useQuery(orderKeys.market(orderId), () => getOrderMarket(orderId), {enabled:!!orderId, staleTime:fiveMinutes});
}
const useOrderPaymentSource = (orderId) => {
    return useQuery(orderKeys.paymentSource(orderId), () => getOrderPaymentSource(orderId), {enabled:!!orderId, staleTime:fiveMinutes});
}
const useOrderRefunds = (orderId) => {
    return useQuery(orderKeys.refunds(orderId), () => getOrderRefunds(orderId), {enabled:!!orderId, staleTime:fiveMinutes});
}
const useOrderShipments = (orderId) => {
    return useQuery(orderKeys.shipments(orderId), () => getOrderShipments(orderId), {enabled:!!orderId, staleTime:fiveMinutes});
}
const useOrderShippingAddress = (orderId) => {
    return useQuery(orderKeys.shippingAddress(orderId), () => getOrderShippingAddress(orderId), {enabled:!!orderId, staleTime:fiveMinutes});
}

const useUpdateCapture = () => {
    const queryClient = useQueryClient();
    const dispatch = useDispatch();
    return useMutation(updateCapture, {
        onError: (err) => {
            dispatch(addMessage({id: uuidv1(), text: `Failed updating Capture: ${err.message}`, priority: `low`}));
        },
        onSuccess: (data, variables) => {
            queryClient.invalidateQueries(orderKeys.detail(variables.orderId)).then();
        }
    });
}


const usePaypalPaymentSearch = (paypalId) => {
    return useQuery([`paypal-payment-search`, paypalId], () => getPaypalPaymentByPaypalId(paypalId), {enabled:!!paypalId, staleTime:fiveMinutes});
}
const useCreatePaypalPayment = () => {
    const queryClient = useQueryClient();
    const dispatch = useDispatch();
    return useMutation(createPaypalPayment,{
        onError: (err) => {
            dispatch(addMessage({id:uuidv1(), text:`Failed creating PayPal payment source: ${err}`, priority: `low`}));
        },
        onSuccess: (data, variables) => {
            queryClient.invalidateQueries(orderKeys.detail(variables.orderId)).then();
        }
    });
}
const useUpdatePaypalPayment = () => {
    const queryClient = useQueryClient();
    const dispatch = useDispatch();
    return useMutation(updatePaypalPayment, {
        onError: (err) => {
            dispatch(addMessage({id: uuidv1(), text: `Failed updating PayPal payment source: ${err}`, priority: `low`}));
        },
        onSuccess: (data, variables) => {
            queryClient.invalidateQueries(orderKeys.detail(variables.orderId)).then();
        }
    });
}

const useUpdateShipment = () => {
    const queryClient = useQueryClient();
    const dispatch = useDispatch();
    return useMutation(updateShipment, {
        onError: (err) => {
            dispatch(addMessage({id: uuidv1(), text: `Failed updating shipment: ${err}`, priority: `low`}));
        },
        onSuccess: (data, variables) => {
            queryClient.invalidateQueries(orderKeys.detail(variables.orderId)).then();
        }
    });
}

export {
    orderKeys,
    useCreateOrder,
    useMyOrders,
    useMyShopOrders,
    useOrder,
    useUpdateOrder,
    useOrderAvailablePaymentMethods,
    useOrderBillingAddress,
    useOrderCaptures,
    useOrderCustomer,
    useOrderMarket,
    useOrderPaymentSource,
    useOrderShipments,
    useOrderRefunds,
    useOrderShippingAddress,
    useUpdateCapture,
    usePaypalPaymentSearch,
    useCreatePaypalPayment,
    useUpdatePaypalPayment,
    useUpdateShipment
}

